import { Organization } from 'utils/types/Organization'

export const topPickOrganizations: Organization[] = [
  {
    orgNumber: '556568-6721',
    orgName: 'Peab Anläggning AB',
  },
  {
    orgNumber: '802400-4825',
    orgName: 'TRR Trygghetsrådet',
  },
  {
    orgNumber: '556056-6258',
    orgName: 'Ericsson AB',
  },
]

export const getRandomOrganization = (): Organization => {
  return topPickOrganizations[
    Math.floor(Math.random() * topPickOrganizations.length)
  ]
}
