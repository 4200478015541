import { useState } from 'react'
import { Employment, Testperson } from 'utils/types/Testperson'
import { createEmployment } from './helpers'
import TestpersonTemplate from 'utils/types/TestpersonTemplate'

// Randomize year between 27 och 65
const randomYear = () => {
  return Math.floor(Math.random() * 38) + 27
}

const getDefaultAge = (age?: number) => {
  return age?.toString() ?? randomYear().toString()
}

const getDefaultEmployments = (employments?: Employment[]) => {
  return employments ?? [createEmployment()]
}

const useTestperson = (testpersonTemplate?: TestpersonTemplate) => {
  const [age, setAge] = useState<string>(getDefaultAge(testpersonTemplate?.age))
  const [employments, setEmployments] = useState<Employment[]>(
    getDefaultEmployments(testpersonTemplate?.employments)
  )

  const testperson: Testperson = {
    age,
    employments,
  }
  const updateOrgNumber = (employmentId: string, newOrgNumber: string) => {
    setEmployments(
      employments.map((employment) => {
        if (employment.id === employmentId) {
          return {
            ...employment,
            orgNumber: newOrgNumber,
            orgName: '',
          }
        }
        return employment
      })
    )
  }

  const updateStartDate = (employmentId: string, newStartDate: Date) => {
    setEmployments(
      employments.map((employment) => {
        if (employment.id === employmentId) {
          return {
            ...employment,
            startDate: newStartDate,
          }
        }
        return employment
      })
    )
  }

  const updateEndDate = (employmentId: string, newEndDate: Date | null) => {
    setEmployments(
      employments.map((employment) => {
        if (employment.id === employmentId) {
          return {
            ...employment,
            endDate: newEndDate,
          }
        }
        return employment
      })
    )
  }

  const addEmployment = () => {
    setEmployments([...employments, createEmployment()])
  }

  const deleteEmployment = (employmentId: string) => {
    setEmployments(
      employments.filter((employment) => employment.id !== employmentId)
    )
  }

  return {
    testperson,
    setAge,
    updateOrgNumber,
    updateStartDate,
    updateEndDate,
    addEmployment,
    deleteEmployment,
  }
}

export default useTestperson
