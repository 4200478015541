const orgNummerRegex = /^\d{6}-?\d{4}$/

const toDigits = (numString: string) =>
  numString.replace(/\D/g, '').split('').map(Number)

const isOrgNumberValid = (orgNumber: string): boolean => {
  if (!orgNummerRegex.test(orgNumber)) {
    return false
  }
  const digits = toDigits(orgNumber)
  const checkDigit = digits[digits.length - 1]
  const total = digits
    .slice(0, -1)
    .reverse()
    .map((current: number, index: number) =>
      index % 2 === 0 ? current * 2 : current
    )
    .map((current: number) => (current > 9 ? current - 9 : current))
    .reduce(
      (current: number, accumulator: number) => current + accumulator,
      checkDigit
    )
  return total % 10 === 0
}

export default isOrgNumberValid
