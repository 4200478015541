import { Button, Tooltip, ClickAwayListener } from '@mui/material'
import React, { PropsWithChildren, useState } from 'react'

type CopyButtonProps = {
  success: string
  failed: string
  textToCopy: string
  testId?: string
}

const CopyButton = ({
  success,
  failed,
  textToCopy,
  testId,
  children,
}: PropsWithChildren<CopyButtonProps>) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [copySucceded, setCopySucceded] = useState(true)

  const title = copySucceded ? success : failed

  const copyClicked = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy)
      setCopySucceded(true)
      setTooltipOpen(true)
    } catch (error) {
      setCopySucceded(false)
      setTooltipOpen(true)
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => void copyClicked()}
          data-testid={testId}
        >
          {children}
        </Button>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default CopyButton
