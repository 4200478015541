import TestpersonTemplate from 'utils/types/TestpersonTemplate'
import useTestperson from '../useTestperson'
import isOrgNumberValid from 'utils/helpers/isOrgNumberValid'

export const isDateRangeValid = (startDate: Date, endDate: Date | null) => {
  if (isNaN(startDate.getTime())) {
    return false
  }
  if (endDate === null) {
    return true
  }
  if (isNaN(endDate.getTime())) {
    return false
  }
  if (startDate > endDate) {
    return false
  }
  return true
}

const useTestpersonWithValidation = (
  testpersonTemplate?: TestpersonTemplate
) => {
  const props = useTestperson(testpersonTemplate)
  const { testperson } = props
  const isAnyOrgNumberInvalid = testperson.employments.some(
    (employment) => !isOrgNumberValid(employment.orgNumber)
  )

  const isAnyDateRangeInvalid = testperson.employments.some(
    (employment) => !isDateRangeValid(employment.startDate, employment.endDate)
  )

  const isAgeInvalid =
    testperson.age === '' ||
    Number.isNaN(Number(testperson.age)) ||
    Number(testperson.age) < 1 ||
    Number(testperson.age) > 120

  const formInvalid =
    isAgeInvalid || isAnyOrgNumberInvalid || isAnyDateRangeInvalid

  return {
    ...props,
    validation: {
      isAgeInvalid,
      isAnyOrgNumberInvalid,
      isAnyDateRangeInvalid,
      formInvalid,
    },
  }
}

export default useTestpersonWithValidation
