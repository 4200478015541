import { useCurrentKey } from '@trr/app-shell-data'
import React from 'react'

interface CurrentKeyRouteProps {
  urlKey: string
}

const CurrentKeyRoute: React.FunctionComponent<
  React.PropsWithChildren<CurrentKeyRouteProps>
> = ({ urlKey, children }) => {
  const currentKey = useCurrentKey()

  if (currentKey !== urlKey) return null

  return <>{children}</>
}

export default CurrentKeyRoute
