import TestpersonTemplate from 'utils/types/TestpersonTemplate'
import testpersonTemplates from '../testpersonTemplates'

const getTestpersonTemplateById = (
  id: string | undefined
): TestpersonTemplate | undefined => {
  return id
    ? testpersonTemplates.find((template) => template?.id === id)
    : undefined
}

export default getTestpersonTemplateById
