import {
  Box,
  Button,
  Divider,
  IconButton,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useEpiContent } from '@trr/app-shell-data'
import React from 'react'
import { useTestpersonWithValidation } from './hooks'
import { useCreateTestPersonMutation } from 'Api'
import { createTestpersonMapper } from 'utils/types/CreateTestperson'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import isOrgNumberValid from 'utils/helpers/isOrgNumberValid'
import OrganizationInfo from 'components/OrganizationInfo'
import CopyButton from 'components/CopyButton'
import getTestpersonTemplateById from 'utils/helpers/getTestpersonTemplateById'
import { useLocation } from 'react-router-dom'

type IEpiContent = {
  heading: string
}

const CreateTestperson = () => {
  const { search } = useLocation()
  const defaultId = new URLSearchParams(search).get('templateId') ?? undefined
  const { heading } = useEpiContent<IEpiContent>()
  const testpersonTemplate = getTestpersonTemplateById(defaultId)
  const {
    testperson,
    setAge,
    updateOrgNumber,
    updateStartDate,
    updateEndDate,
    addEmployment,
    deleteEmployment,
    validation: { isAgeInvalid, formInvalid },
  } = useTestpersonWithValidation(testpersonTemplate)
  const [createTestperson, result] = useCreateTestPersonMutation()

  const handleSubmit = () => {
    void createTestperson({
      data: createTestpersonMapper(testperson),
    })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={4}>
        {heading}
        <Stack spacing={2} direction="row" alignItems="center">
          <InputLabel htmlFor="age-input" sx={{ fontSize: '18px' }}>
            Ålder:
          </InputLabel>
          <TextField
            id="age-input"
            size="small"
            value={testperson.age}
            sx={{
              width: 100,
              '& .MuiFormHelperText-root.Mui-error': {
                position: 'absolute',
                top: '90%',
              },
            }}
            onChange={(event) => setAge(event.target.value)}
            inputProps={{ 'data-testid': 'age-input' }}
            error={isAgeInvalid}
            helperText={isAgeInvalid ? 'Ange ålder' : undefined}
          />
        </Stack>

        <Stack spacing={2}>
          {testperson.employments.map((employment) => {
            const orgNumberInvalid = !isOrgNumberValid(employment.orgNumber)
            return (
              <Paper
                key={employment.id}
                data-testid="employment-row"
                elevation={3}
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Stack direction={'row'} alignItems="center">
                  {orgNumberInvalid ? (
                    <Typography
                      variant="subtitle1"
                      color={(theme) => theme.palette.error.main}
                    >
                      Organisationsnumret är inte giltigt
                    </Typography>
                  ) : (
                    <OrganizationInfo orgNumber={employment.orgNumber} />
                  )}
                  <Box flexGrow={1} />
                  <IconButton
                    sx={{ height: 40 }}
                    onClick={() => deleteEmployment(employment.id)}
                    data-testid="delete-employment-button"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack
                  spacing={2}
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems={{ xs: 'flex-start', md: 'center' }}
                >
                  <TextField
                    sx={{ width: { xs: '100%', md: 300 } }}
                    inputProps={{ 'data-testid': 'org-number-input' }}
                    value={employment.orgNumber}
                    label="Organisationsnummer"
                    onChange={(event) =>
                      updateOrgNumber(employment.id, event.target.value)
                    }
                    error={orgNumberInvalid}
                  />

                  <DatePicker
                    label="Startdatum"
                    sx={{ width: { xs: '100%', md: 200 } }}
                    value={employment.startDate}
                    format="yyyy-MM-dd"
                    onChange={(event) =>
                      updateStartDate(employment.id, event ?? new Date())
                    }
                  />
                  <DatePicker
                    label="Slutdatum"
                    sx={{ width: { xs: '100%', md: 200 } }}
                    value={employment.endDate}
                    format="yyyy-MM-dd"
                    onChange={(event) => updateEndDate(employment.id, event)}
                    slotProps={{
                      field: {
                        clearable: true,
                      },
                    }}
                  />
                </Stack>
              </Paper>
            )
          })}

          <Button
            size="small"
            variant="text"
            onClick={addEmployment}
            data-testid="add-employment-button"
          >
            Lägg till anställning
          </Button>
        </Stack>
        <Divider />
        <Button
          onClick={handleSubmit}
          disabled={formInvalid}
          data-testid="create-testperson-button"
        >
          Skapa testperson
        </Button>
        {result.isLoading && <Typography>Skapar testperson...</Typography>}
        {result.isSuccess && (
          <Stack spacing={2}>
            <Typography>Testperson skapad!</Typography>
            <Stack direction={'row'} spacing={2}>
              <TextField
                inputProps={{ 'data-testid': 'social-secury-number-input' }}
                value={result.data.socialSecurityNumber}
                label="Personnumer"
              />
              <TextField
                inputProps={{ 'data-testid': 'password-input' }}
                value={result.data.password}
                label="Lösenord"
              />
              <CopyButton
                success="Kopierat!"
                failed="Kunde inte kopiera"
                textToCopy={`${result.data.socialSecurityNumber}<->${result.data.password}`}
              >
                Kopiera
              </CopyButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    </LocalizationProvider>
  )
}

export default CreateTestperson
