import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { medarbetareApi, publicWebApi } from 'Api'

const rootReducer = combineReducers({
  [medarbetareApi.reducerPath]: medarbetareApi.reducer,
  [publicWebApi.reducerPath]: publicWebApi.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        medarbetareApi.middleware,
        publicWebApi.middleware,
      ]),
    preloadedState,
  })

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export default store
