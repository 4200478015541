import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getConfig } from '../utils'
import { MembershipByOrgNumberResponse } from 'utils/types/MembershipByOrgNumberResponse'

const { PUBLIC_WEB_API_URL } = getConfig()

const extendedFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: PUBLIC_WEB_API_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const publicWebApi = createApi({
  reducerPath: 'publicWebApi',
  baseQuery: extendedFetchBaseQuery(),
  endpoints: (builder) => ({
    getMembershipByOrgNumber: builder.query<
      MembershipByOrgNumberResponse,
      string
    >({
      query: (orgNumber) =>
        `/workplace/search/membership-by-org-number?query=${orgNumber}`,
    }),
  }),
})

export const { useGetMembershipByOrgNumberQuery } = publicWebApi

export default publicWebApi
