import getDateOfBirth from 'utils/helpers/getDateOfBirth'
import { Testperson } from './Testperson'

export type Employment = {
  orgNumber: string
  startDate: string
  endDate?: string
}

export type CreateTestpersonModel = {
  dateOfBirth: string
  employment: Employment[]
}

export type CreateTestpersonResponse = {
  socialSecurityNumber: string
  password: string
}

const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0]
}

export const createTestpersonMapper = (
  testperson: Testperson
): CreateTestpersonModel => {
  return {
    dateOfBirth: getDateOfBirth(Number.parseInt(testperson.age)),
    employment: testperson.employments.map((arbetsgivare) => ({
      orgNumber: arbetsgivare.orgNumber,
      startDate: formatDate(arbetsgivare.startDate),
      endDate: arbetsgivare.endDate
        ? formatDate(arbetsgivare.endDate)
        : undefined,
    })),
  }
}
