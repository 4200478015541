import React from 'react'
import CurrentKeyRoute from 'components/CurrentKeyRoute'
import Start from 'features/Start'
import CreateTestperson from 'features/CreateTestperson'
import SelectTestpersonTemplate from 'features/SelectTestpersonTemplate'

const App = () => {
  return (
    <>
      <CurrentKeyRoute urlKey="creed">
        <Start />
      </CurrentKeyRoute>
      <CurrentKeyRoute urlKey="skapa-testperson">
        <CreateTestperson />
      </CurrentKeyRoute>
      <CurrentKeyRoute urlKey="skapa-testperson-fran-mall">
        <SelectTestpersonTemplate />
      </CurrentKeyRoute>
    </>
  )
}

export default App
