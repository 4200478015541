import { Skeleton, Typography } from '@mui/material'
import { useGetMembershipByOrgNumberQuery } from 'Api'
import React from 'react'

interface OrganizationInfoProps {
  orgNumber: string
}

const OrganizationInfo = ({ orgNumber }: OrganizationInfoProps) => {
  const { data, isError, isLoading, isFetching } =
    useGetMembershipByOrgNumberQuery(orgNumber)

  const organization = data?.results[0]

  if (isLoading || isFetching) {
    return <Skeleton width="250px" data-testid="organization-loader" />
  }

  if (isError) {
    return <span data-testid="organization-empty" />
  }

  if (!organization) {
    return (
      <Typography component="p" variant="h6">
        Ej anslutet företag
      </Typography>
    )
  }
  return (
    <Typography component="p" variant="h6">
      {organization.name}
    </Typography>
  )
}

export default OrganizationInfo
