import { getRandomOrganization } from 'utils/helpers/topPickOrganizations'
import { Employment } from 'utils/types/Testperson'

export const createEmployment = (): Employment => {
  const organization = getRandomOrganization()

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const fourYearsAgo = new Date(yesterday)
  fourYearsAgo.setFullYear(fourYearsAgo.getFullYear() - 4)

  return {
    id: new Date().getTime().toString(),
    orgNumber: organization.orgNumber,
    startDate: fourYearsAgo,
    endDate: null,
  }
}
