import { sub, startOfWeek, endOfWeek } from 'date-fns'
import { Employment } from 'utils/types/Testperson'
import TestpersonTemplate from 'utils/types/TestpersonTemplate'

const now = new Date()

const currentJob: Employment = {
  id: 'fbe443c4-b337-49fb-8652-a46550050491',
  orgNumber: '802400-4825',
  endDate: null,
  startDate: startOfWeek(sub(now, { years: 3 })),
}

const pastJob: Employment = {
  id: '1064ea3c-5692-44da-8faf-36f150ab43cb',
  orgNumber: '556033-9086',
  endDate: endOfWeek(sub(currentJob.startDate, { days: 1 })),
  startDate: startOfWeek(sub(currentJob.startDate, { years: 2, days: 1 })),
}

const template: TestpersonTemplate = {
  id: '4845179f-6273-41a2-9059-e0b583ebb506',
  title: 'För ung för stöd',
  description:
    'Personen är för ung för att få stöd då den är under 27 år, även fast hen har jobbat i 3 år. Personen är fortfarande anställd.',
  age: 25,
  employments: [currentJob, pastJob],
}

export default template
