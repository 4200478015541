import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getIdToken } from '@trr/app-shell-data'
import { getConfig } from '../utils'
import {
  CreateTestpersonModel,
  CreateTestpersonResponse,
} from 'utils/types/CreateTestperson'

const { MEDARBETARE_API_URL } = getConfig()

const extendedFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: MEDARBETARE_API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken()}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

export const medarbetareApi = createApi({
  reducerPath: 'medarbetareApi',
  baseQuery: extendedFetchBaseQuery(),
  endpoints: (builder) => ({
    createTestPerson: builder.mutation<
      CreateTestpersonResponse,
      { data: CreateTestpersonModel }
    >({
      queryFn: (args) => {
        const date = args.data.dateOfBirth.split('-').join('')
        return {
          data: {
            socialSecurityNumber: `${date}-1234`,
            password: 'fotboll1',
          },
        }
      },
    }),
  }),
})

export const { useCreateTestPersonMutation } = medarbetareApi

export default medarbetareApi
