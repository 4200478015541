import React from 'react'
import { List } from '@mui/material'
import TestpersonTemplateItem from 'components/TestpersonTemplateItem'
import TestpersonTemplate from 'utils/types/TestpersonTemplate'

type TestpersonTemplateListProps = {
  templates: TestpersonTemplate[]
  onItemClick: (item: TestpersonTemplate) => void
  dataTestId?: string
  itemProps?: {
    dataTestId?: (item: TestpersonTemplate) => string
  }
}

const TestpersonTemplateList = ({
  templates,
  onItemClick,
  dataTestId,
  itemProps,
}: TestpersonTemplateListProps) => {
  return (
    <List data-testid={dataTestId}>
      {templates.map((template) => (
        <TestpersonTemplateItem
          key={template.id}
          description={template.description}
          onClick={() => onItemClick(template)}
          dataTestId={itemProps?.dataTestId?.(template)}
        />
      ))}
    </List>
  )
}

export default TestpersonTemplateList
