import React from 'react'
import { ListItem, ListItemButton } from '@mui/material'

type TestpersonTemplateItemProps = {
  description: string
  onClick: React.MouseEventHandler<HTMLDivElement>
  dataTestId?: string
}

const TestpersonTemplateItem = ({
  description,
  onClick,
  dataTestId,
}: TestpersonTemplateItemProps) => {
  return (
    <ListItem>
      <ListItemButton onClick={onClick} data-testid={dataTestId}>
        {description}
      </ListItemButton>
    </ListItem>
  )
}

export default TestpersonTemplateItem
