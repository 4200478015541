import React from 'react'
import { Box } from '@mui/material'
import TestpersonTemplateList from 'components/TestpersonTemplateList'
import templates from 'utils/testpersonTemplates'
import { useHistory } from 'react-router-dom'
import TestpersonTemplate from 'utils/types/TestpersonTemplate'

const SelectTestpersonTemplate = () => {
  const history = useHistory()

  const navigateWithTemplateId = (template: TestpersonTemplate) => {
    history.push({
      pathname: '/medarbetare/creed/skapa-testperson',
      search: `?templateId=${template.id}`,
    })
  }

  return (
    <Box data-testid="select-testperson-template">
      <TestpersonTemplateList
        templates={templates}
        onItemClick={navigateWithTemplateId}
        itemProps={{
          dataTestId: (template) => `testperson-template-item-${template.id}`,
        }}
      />
    </Box>
  )
}

export default SelectTestpersonTemplate
