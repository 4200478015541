import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { Box, Link, Stack, Typography } from '@mui/material'

type IEpiContent = {
  heading: string
  lankar: {
    'skapa-testperson': string
    'skapa-testperson-fran-mall': string
  }
}

const Start = () => {
  const { heading, lankar } = useEpiContent<IEpiContent>()
  return (
    <Box>
      <Typography variant="h1">{heading}</Typography>
      <Stack spacing={2}>
        <Link
          href={lankar?.['skapa-testperson']}
          data-testid="create-testperson-link"
        >
          Skapa testperson
        </Link>
        <Link
          href={lankar?.['skapa-testperson-fran-mall']}
          data-testid="create-testperson-template-link"
        >
          Skapa testperson från mall
        </Link>
      </Stack>
    </Box>
  )
}

export default Start
